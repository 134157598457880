<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:49:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 14:22:40
 * @Description: 我的素质课程
-->
<style lang="scss" scoped>
.mine-courses {
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);
  @include pageTab_school;
  @include pageFoot;
  .course-container {
    width: 100%;
    height: calc(100% - 116px);
    box-sizing: border-box;
    padding-top: 20px;
    flex-direction: column;
    position: relative;
    @include flexBox($align: flex-start);
  }
  .course-inner {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    .course-list {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      flex-wrap: wrap;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox($align: flex-start);
      .course-item {
        width: calc((100% - 60px) / 4);
        height: 342px;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        cursor: default;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }

        .el-image {
          width: 100%;
          height: 218px;
        }

        .title {
          width: calc(100% - 30px);
          height: 56px;
          line-height: 56px;
          color: #3c3b3b;
          border-bottom: 1px solid #ececec;
          margin: 0 auto;
        }

        .target {
          width: calc(100% - 30px);
          height: 64px;
          margin: 0 auto;
          box-sizing: border-box;
          @include flexBox(space-between);
          &.coll {
            .price {
              margin-right: 0;
            }
          }
          .price {
            font-size: 14px;
            color: #f66478;
            margin-right: auto;
            .pf_bold {
              font-size: 18px;
            }
          }
          .collect {
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #f66478;
            cursor: pointer;
            margin-right: auto;
          }
          .el-button {
            margin-left: 10px;
          }
        }

        &:hover {
          box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15),
            5px 5px 27px -10px rgba(108, 78, 203, 0.15);
        }
      }
      .empty {
        width: 375px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .mine-courses {
    @include innerPage($p: 18px 0 0);
    .course-container {
      height: calc(100% - 84px);
    }
    .course-inner .course-list {
      .course-item {
        width: calc((100% - 30px) / 3);
        height: 322px;
        margin-right: 15px;
        margin-bottom: 14px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(4n) {
          margin-right: 15px;
        }
        .title {
          height: 42px;
          line-height: 42px;
        }
        .target {
          height: 56px;
        }
      }
    }
  }
}
</style>

<template>
  <section class="mine-courses">
    <div class="page-title">
      <h3>我的</h3>
      <el-button type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <!-- 一级菜单分类 -->
    <div class="page-tab">
      <ul class="tabs bold">
        <li
          class="tab"
          :class="{ current: plstu_cur_col_status == 10 }"
          @click="checkStatus(10)"
        >
          已购买
        </li>
        <li
          class="tab"
          :class="{ current: plstu_cur_col_status == 20 }"
          @click="checkStatus(20)"
        >
          待付款
        </li>
        <li
          class="tab"
          :class="{ current: plstu_cur_col_status == 30 }"
          @click="checkStatus(30)"
        >
          收藏
        </li>
      </ul>
    </div>
    <div class="course-container">
      <div
        class="course-inner"
        :class="{ 'no-data': $isEmpty(listData) }"
        v-loading="dataLoad"
      >
        <ul class="course-list" v-show="!$isEmpty(listData)">
          <li
            class="course-item"
            v-for="item in listData"
            :key="item.plstu_cur_id"
          >
            <el-image fit="cover" :src="formatfile(item.plstu_cur_image)" />
            <h5 class="bold title line-text--1st">
              {{ item.plstu_cur_title }}
            </h5>
            <div class="target" :class="{ coll: plstu_cur_col_status == 30 }">
              <i
                class="iconfont collect"
                v-show="plstu_cur_col_status == 30"
                @click="handleCancelCollect(item.plstu_cur_id)"
                >&#xe8c2;</i
              >
              <p class="price">
                <span v-show="item.plstu_cur_price > 0">￥</span>
                <span class="pf_bold">{{
                  (item.plstu_cur_price > 0 && item.plstu_cur_price) || "免费"
                }}</span>
              </p>
              <el-button
                type="custom_success"
                size="mini"
                @click="
                  $router.push({
                    name: 'QUALITY_MINE_DETAIL',
                    params: { id: item.plstu_cur_id },
                  })
                "
                >查看</el-button
              >
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-show="$isEmpty(listData) && !dataLoad">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无课程资源哦~</p>
        </div>
      </div>
      <!-- 分页控件 -->
      <div class="custom-foot">
        <customPagination
          :current="listPage.pageIndex"
          :total="listPage.total"
          :size="listPage.pageSize"
          @pageChange="flippingPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  stucurriculumcollectionList,
  stucurriculumcollectionDelete,
} from "@api/center";
import customPagination from "@comp/customPagination";
import { formatFile } from "@utils";
export default {
  components: { customPagination },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      plstu_cur_col_status: 10, // 课程状态
      listData: [], // 课程列表数据
      /* 表格分页参数 */
      listPage: {
        pageIndex: 1,
        pageSize: 12,
        total: 0,
      },
      dataLoad: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.dataLoad = true;
      let params = {
        plstu_cur_col_status: this.plstu_cur_col_status,
        pageindex: this.listPage.pageIndex,
        pagesize: this.listPage.pageSize,
      };
      let { data: res } = await stucurriculumcollectionList(params);
      this.dataLoad = false;
      this.listPage.total = res.allcount;
      this.listData = res.data;
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 切换课程状态 */
    checkStatus(val) {
      if (this.plstu_cur_col_status == val) return;
      this.plstu_cur_col_status = val;
      this.listData = [];
      this.listPage.pageIndex;
      this.getData();
    },
    /** 取消收藏 */
    async handleCancelCollect(plstu_cur_id) {
      await stucurriculumcollectionDelete({ plstu_cur_id });
      this.getData();
    },
  },
};
</script>